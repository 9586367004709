import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBreadcrumb } from 'interfaces'
import { DatePairType } from 'components/DateRangeFilter/DateRangeFilter'
import { DatePeriod, AppScheme, AppTitle } from 'enums'
import { APP_URL } from 'config/app'
import { Block } from 'layout'
import { ReportsFreedomRankCard } from 'containers/ReportsFreedom/components'
import { useFetchMlmReportsRanksMax, useFetchMlmReportsRanksNew } from 'containers/ReportsFreedom/hooks'
import {
    PageTitle,
    Breadcrumbs,
    DateRangeFilter,
    Loader,
    NoDataInfo,
} from 'components'
import { vBlue9 } from 'styles/modules/variables.module.css'
import { ReportsFreedomRankUser } from './components'
import style from './ReportsFreedomRanks.module.css'
import styleReports from '../ReportsFreedom/ReportsFreedom.module.css'

const DATE_RANGE_FILTER_PERIODS = {
    pv: [
        DatePeriod.month,
        DatePeriod.year,
        DatePeriod.all,
        DatePeriod.custom,
    ],
    cc: [
        DatePeriod.week,
        DatePeriod.month,
        DatePeriod.year,
        DatePeriod.all,
        DatePeriod.custom,
    ],
}

const ReportsFreedomRanks: React.FC = () => {
    const { state }: { state?: { type?: keyof typeof AppScheme } } = useLocation()
    const { t } = useTranslation()

    const [type] = useState<keyof typeof AppScheme>(state?.type!)
    const [dateFilter, setDateFilter] = useState<DatePairType>()
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([
        { id: 1, name: t('reports'), url: APP_URL.reports },
        { id: 2, name: AppTitle[type] },
    ])

    const {
        isInitialLoading: isLoading,
        data: dataMlmReportsRankUserMax,
    } = useFetchMlmReportsRanksMax({ type, line: 0 })

    const {
        isInitialLoading: isLoadingRankUserListNew,
        data: dataMlmReportsRankUserListNew,
    } = useFetchMlmReportsRanksNew({
        type,
        dateFrom: dateFilter?.dateFrom,
        dateTo: dateFilter?.dateTo,
    }, {
        keepPreviousData: true,
        enabled: !!dateFilter,
    })

    const {
        isInitialLoading: isLoadingRankUserListMax,
        data: dataMlmReportsRankUserListMax,
    } = useFetchMlmReportsRanksMax({ type })

    const handlerChangeDateFilter = (dates: DatePairType) => {
        setDateFilter(dates)
    }

    useEffect(() => {
        if (dataMlmReportsRankUserMax?.length) {
            setBreadcrumbs((prevState) => [
                ...prevState,
                { id: 3, name: dataMlmReportsRankUserMax[0].name },
            ])
        }
    }, [dataMlmReportsRankUserMax])

    return (
        <>
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            {isLoading && (
                <Loader />
            )}

            {!isLoading && !dataMlmReportsRankUserMax?.length && (
                <NoDataInfo text={t('no_data')} />
            )}

            {!!dataMlmReportsRankUserMax?.length && (
                <>
                    <Block classes={style.block}>
                        <ReportsFreedomRankUser data={dataMlmReportsRankUserMax[0]} />
                    </Block>
                    <Block classes={styleReports.block}>
                        <Block.Header title={t('reports_freedom_new_ranks')} />
                        <div className={styleReports.blockBody}>
                            <DateRangeFilter
                                classes={style.rankFilter}
                                periods={DATE_RANGE_FILTER_PERIODS[type]}
                                activeColor={type === 'pv' ? vBlue9 : '#ff4e1f'}
                                onChange={handlerChangeDateFilter}
                            />
                            {dataMlmReportsRankUserListNew && (
                                <div className={styleReports.rankList}>
                                    {dataMlmReportsRankUserListNew.map((item) => (
                                        <div className={styleReports.rankListItem} key={item.id}>
                                            <ReportsFreedomRankCard
                                                data={item}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            {!isLoadingRankUserListNew && !dataMlmReportsRankUserListNew?.length && (
                                <NoDataInfo text={t('no_data')} />
                            )}
                        </div>
                    </Block>
                    <Block classes={styleReports.block}>
                        <Block.Header title={t('reports_freedom_max_ranks')} />
                        {dataMlmReportsRankUserListMax && (
                            <div className={cn(styleReports.blockBody, styleReports.rankList)}>
                                {dataMlmReportsRankUserListMax.map((item) => (
                                    <div className={styleReports.rankListItem} key={item.id}>
                                        <ReportsFreedomRankCard
                                            data={item}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        {!isLoadingRankUserListMax && !dataMlmReportsRankUserListMax?.length && (
                            <NoDataInfo text={t('no_data')} />
                        )}
                    </Block>
                </>
            )}
        </>
    )
}

export default ReportsFreedomRanks
