import React, { useState, useEffect, useRef } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import {
    ICommunity,
    ICommunityDefaultImage,
    IGoodsData,
    IPostContent,
    IPostContentAttacheParams,
    IPostFileCommon,
    ICalendarPostProps, ICalendarPost,
} from 'interfaces'
import { FormDataType, FieldName } from 'forms/CalendarPostForm/CalendarPostForm'
import { TAddCalendarPostProps, TUpdateCalendarPostProps } from 'services/CommunityService'
import { TAddFileFnParams, TAddGoodsFnParams, TAddImageFnParams } from 'hooks/usePost'
import { useMutationPost } from 'containers/Community/hooks'
import { CatalogProductSelector, PhotoBankImageSelector, Modal } from 'components'
import { CalendarPostForm } from 'forms'
import { useAlertDialog, usePost } from 'hooks'
import {
    defaultDateFormat,
    showAlertNotify,
    getFileTypeByMimeType,
    getId,
    parseTpl,
} from 'utils/helpers'
import { validate } from 'utils/validators'
import styleOverlay from 'styles/modules/overlay.module.css'

type CalendarEventActionPropType = {
    postProps: FormDataType
    date?: Date
    communities?: ICommunity[]
    onComplete: (value: ICalendarPost) => void
}

const TITLE_MAX_LENGTH = 149
const TEXT_MAX_LENGTH = 10000
const DATE_END_DIF_TIME = 3600 * 1000 // 1h

const getDefaultDateTimeStart = (value: Date) => {
    const dateCurrent = new Date()
    const dateYear = value.getFullYear()
    const dateMonth = value.getMonth()
    const dateDay = value.getDate()
    // TODO dateCurrent.getHours() + 1 -> +15 min
    return new Date(dateYear, dateMonth, dateDay, dateCurrent.getHours() + 1, 0)
}

const getDefaultDateTimeEnd = (value: Date) => {
    const dateCurrent = new Date()
    const dateYear = value.getFullYear()
    const dateMonth = value.getMonth()
    const dateDay = value.getDate()

    return new Date(dateYear, dateMonth, dateDay, dateCurrent.getHours() + 1 + (DATE_END_DIF_TIME / (3600 * 1000)), 0)
}

const getPostProps = (data: FormDataType, date?: Date): TAddCalendarPostProps => {
    if (date) {
        const noSetTime = date.getHours() === 0 && date.getMinutes() === 0
        const dateStart = noSetTime ? getDefaultDateTimeStart(date) : date
        const dateEnd = noSetTime ? getDefaultDateTimeEnd(date) : new Date(date.getTime() + DATE_END_DIF_TIME)

        return {
            ...data,
            [FieldName.dateStart]: defaultDateFormat(dateStart, true, true),
            [FieldName.dateEnd]: defaultDateFormat(dateEnd, true, true),
        }
    }
    return data
}

const CalendarPostAction: React.FC<CalendarEventActionPropType> = ({
    postProps,
    date,
    communities,
    onComplete,
}) => {
    const isMounted = useRef(false)
    const { t } = useTranslation()
    const { showAlertDialog } = useAlertDialog()

    const {
        getMainText,
        getMainFiles,
        getContent,
        getTextResult,
        getFilesResult,
        addFile,
        addGoods,
        addImage,
        addContent,
        deletePostFile,
        deleteContentFile,
    } = usePost()

    const [props] = useState<FormDataType>(getPostProps(postProps, date))
    /** All post main files */
    const [postFiles, setPostFiles] = useState<ICalendarPostProps['files']>(getMainFiles(props[FieldName.files]))
    /** Parsed post text by tags */
    const [postContent, setPostContent] = useState<IPostContent[]>(getContent(
        props[FieldName.text],
        props[FieldName.files],
    ))
    const [initialValues, setInitialValues] = useState<FormDataType>({
        ...props,
        [FieldName.text]: getMainText(props[FieldName.text]),
    })
    const [goodsAttachParams, setGoodsAttachParams] = useState<IPostContentAttacheParams>({})
    const [imageAttachParams, setImageAttachParams] = useState<IPostContentAttacheParams>({})

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isOpenModalCatalog, setIsOpenModalCatalog] = useState(false)
    const [isOpenModalImage, setIsOpenModalImage] = useState(false)

    const { addCalendarPost, updateCalendarPost } = useMutationPost()

    const handlerSubmit = (params: FormDataType) => {
        const text = getTextResult(params.text, postContent)
        const files = getFilesResult(postFiles, postContent)

        if ('id' in params) {
            updateCalendarPostAction({ ...params, files, text })
        } else {
            addCalendarPostAction({ ...params, files, text })
        }
    }

    const handlerAddFiles = (files: FileList, params: IPostContentAttacheParams) => {
        const [file] = files

        if (file) {
            addFileAction({ file, params, data: { files: postFiles, content: postContent } })
        }
    }

    const handlerAddGoods = (params: IPostContentAttacheParams) => {
        setGoodsAttachParams(params)
        setIsOpenModalCatalog(true)
    }

    const handlerAddImage = (params: IPostContentAttacheParams) => {
        setImageAttachParams(params)
        setIsOpenModalImage(true)
    }

    const handlerChangeContentDesc = (id: number, value: string) => {
        setPostContent((prevState) => prevState.map((item) => {
            return item.id === id ? { ...item, html: value } : item
        }))
    }

    const handlerSelectGoods = (goods: IGoodsData) => {
        const { id: goodsId, images = [] } = goods || {}
        const [image] = images

        if (goodsId && image) {
            addGoodsAction({ goods, params: goodsAttachParams, data: { files: postFiles, content: postContent } })
            closeModalCatalog()
        }
    }

    const handlerSelectImage = (image: ICommunityDefaultImage) => {
        addImageAction({ image, params: imageAttachParams, data: { files: postFiles, content: postContent } })
        closeModalImage()
    }

    const handlerDeletePostFile = (id: number) => {
        showAlertDialog<{ id: number }>({
            message: `${t('delete')}?`,
            payload: { id },
            onConfirm: removePostFile,
        })
    }

    const handlerDeleteContentFile = (id: number) => {
        showAlertDialog<{ id: number }>({
            message: `${t('delete')}?`,
            payload: { id },
            onConfirm: removeContentFile,
        })
    }

    const handlerCloseModalCatalog = () => {
        closeModalCatalog()
    }

    const handlerCloseModalImage = () => {
        closeModalImage()
    }

    function closeModalCatalog() {
        setGoodsAttachParams({})
        setIsOpenModalCatalog(false)
    }

    function closeModalImage() {
        setImageAttachParams({})
        setIsOpenModalImage(false)
    }

    function addPostFile(data: IPostFileCommon) {
        setPostFiles((prevState) => [...prevState, data])
    }

    function removePostFile({ id }: { id: number }) {
        setPostFiles((prevState) => deletePostFile(prevState, id))
    }

    function addPostContent(data: IPostContent, groupId?: number) {
        setPostContent((prevState) => addContent(prevState, data, groupId))
    }

    function removeContentFile({ id }: { id: number }) {
        setPostContent((prevState) => deleteContentFile(prevState, id))
    }

    function addCalendarPostAction(params: TAddCalendarPostProps) {
        setIsSubmitting(true)
        addCalendarPost.mutate(params, {
            onSuccess: (res) => {
                onComplete(res)
            },
            onError: ([errorText]) => {
                showAlertNotify({ type: 'error', message: errorText })
            },
            onSettled: () => {
                if (isMounted.current) {
                    setIsSubmitting(false)
                }
            },
        })
    }

    function updateCalendarPostAction(params: TUpdateCalendarPostProps) {
        setIsSubmitting(true)
        updateCalendarPost.mutate(params, {
            onSuccess: (res) => {
                onComplete(res)
            },
            onError: ([errorText]) => {
                showAlertNotify({ type: 'error', message: errorText })
            },
            onSettled: () => {
                if (isMounted.current) {
                    setIsSubmitting(false)
                }
            },
        })
    }

    function addFileAction({ file, params: { isAttach, contentId }, data }: TAddFileFnParams) {
        setIsSubmitting(true)
        addFile({ file, params: { isAttach, contentId }, data })
            .then((fileData) => {
                if (isAttach) {
                    addPostContent({
                        id: contentId || getId(true),
                        type: getFileTypeByMimeType(fileData.type).type,
                        html: '',
                        text: '',
                        files: [fileData],
                    }, fileData.fileGroup)
                } else {
                    addPostFile(fileData)
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function addGoodsAction({ goods, params: { isAttach, contentId }, data }: TAddGoodsFnParams) {
        const { name } = goods

        setIsSubmitting(true)
        addGoods({ goods, params: { isAttach, contentId }, data })
            .then((fileData) => {
                if (isAttach) {
                    addPostContent({
                        id: contentId || getId(true),
                        type: 'image',
                        html: '',
                        text: name,
                        files: [fileData],
                    })
                } else {
                    addPostFile(fileData)
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function addImageAction({ image, params: { isAttach, contentId }, data }: TAddImageFnParams) {
        addImage({ image, params: { isAttach, contentId }, data })
            .then((fileData) => {
                if (isAttach) {
                    addPostContent({
                        id: contentId || getId(true),
                        type: 'image',
                        html: '',
                        text: '',
                        files: [fileData],
                    })
                } else {
                    addPostFile(fileData)
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (communities?.length) {
            const [communityDefault] = communities

            setInitialValues((prevState) => ({ ...prevState, community: communityDefault.community.id }))
        }
    }, [communities])

    return (
        <>
            <Form
                initialValues={initialValues}
                onSubmit={handlerSubmit}
                mutators={{
                    [FieldName.community]: ([key, value]: [FieldName.community, number], state, { changeValue }) => {
                        changeValue(state, key, () => value)
                    },
                    [FieldName.dateStart]: ([key, value]: [string, Date], state, { changeValue }) => {
                        const fieldDateEndValue = state.formState.values[FieldName.dateEnd]

                        let dateStart: Date = value
                        let dateEnd: Date|undefined = fieldDateEndValue ? new Date(fieldDateEndValue) : undefined

                        if (dateStart.getHours() === 0 && dateStart.getMinutes() === 0) {
                            dateStart = getDefaultDateTimeStart(dateStart)
                        }

                        if (!dateEnd || dateStart.getTime() > dateEnd.getTime()) {
                            dateEnd = new Date(
                                dateStart.getFullYear(),
                                dateStart.getMonth(),
                                dateStart.getDate(),
                                dateStart.getHours() + (DATE_END_DIF_TIME / (3600 * 1000)),
                                dateStart.getMinutes(),
                            )
                            changeValue(state, FieldName.dateEnd, () => defaultDateFormat(dateEnd, true, true))
                        }

                        changeValue(state, FieldName.dateStart, () => defaultDateFormat(dateStart, true, true))
                    },
                    [FieldName.dateEnd]: ([key, value]: [string, Date], state, { changeValue }) => {
                        const fieldDateStartValue = state.formState.values[FieldName.dateStart]

                        let dateStart: Date|undefined = fieldDateStartValue ? new Date(fieldDateStartValue) : undefined
                        let dateEnd: Date = value

                        if (dateEnd.getHours() === 0 && dateEnd.getMinutes() === 0) {
                            dateEnd = getDefaultDateTimeEnd(dateEnd)
                        }

                        if (!dateStart || dateStart.getTime() > dateEnd.getTime()) {
                            dateStart = new Date(
                                dateEnd.getFullYear(),
                                dateEnd.getMonth(),
                                dateEnd.getDate(),
                                dateEnd.getHours() - (DATE_END_DIF_TIME / (3600 * 1000)),
                                0,
                            )
                            changeValue(state, FieldName.dateStart, () => defaultDateFormat(dateStart, true, true))
                        }

                        changeValue(state, FieldName.dateEnd, () => defaultDateFormat(dateEnd, true, true))
                    },
                }}
                validate={(values) => {
                    const errors = {}

                    if (!values[FieldName.title]) {
                        errors[FieldName.title] = t('error_field_is_empty')
                    }
                    if (values[FieldName.title] && !validate('max', values[FieldName.title], { max: TITLE_MAX_LENGTH })) {
                        errors[FieldName.title] = parseTpl(
                            t('form_validation_length_limit'),
                            { '%d': TITLE_MAX_LENGTH },
                            { prefix: '', suffix: '' },
                        )
                    }
                    if (!values[FieldName.text]) {
                        errors[FieldName.text] = t('error_field_is_empty')
                    }
                    if (values[FieldName.text] && !validate('max', values[FieldName.text], { max: TEXT_MAX_LENGTH })) {
                        errors[FieldName.text] = parseTpl(
                            t('form_validation_length_limit'),
                            { '%d': TEXT_MAX_LENGTH },
                            { prefix: '', suffix: '' },
                        )
                    }

                    return errors
                }}
                render={({ handleSubmit }) => (
                    <CalendarPostForm
                        isSubmitting={isSubmitting}
                        files={postFiles}
                        content={postContent}
                        communities={communities}
                        onAddFiles={handlerAddFiles}
                        onAddGoods={handlerAddGoods}
                        onAddImage={handlerAddImage}
                        onChangeContentDesc={handlerChangeContentDesc}
                        onDeletePostFile={handlerDeletePostFile}
                        onDeleteContentFile={handlerDeleteContentFile}
                        onSubmit={handleSubmit}
                    />
                )}
            />

            <Modal
                isOpen={isOpenModalCatalog}
                size="medium"
                classesOverlay={styleOverlay.overlay_medium}
                onClose={handlerCloseModalCatalog}
            >
                <Modal.Header
                    isCloseButton
                    titlePos="left"
                    title={t('market_select_product')}
                />
                <Modal.Body background>
                    <CatalogProductSelector
                        onSelectGoods={handlerSelectGoods}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                isOpen={isOpenModalImage}
                size="medium"
                classesOverlay={styleOverlay.overlay_light}
                onClose={handlerCloseModalImage}
            >
                <Modal.Header
                    isCloseButton
                    titlePos="left"
                    title={t('posts_default_images')}
                />
                <Modal.Body background>
                    <PhotoBankImageSelector
                        onSelectImage={handlerSelectImage}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CalendarPostAction
