import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    IRootState,
    IPayout,
    ICurrency,
    IReport,
} from 'interfaces'
import { BREAKPOINTS, CLIENT_STORAGE_WALLET_CURRENCY_KEY } from 'config/app'
import {
    ContentContainer,
    SideBarContainer,
    ContentBackgroundBottom,
} from 'layout'
import { useWindowResize } from 'hooks'
import { StorageService } from 'services'
import { priceFormat, scrollTop, getCountFractionDigits } from 'utils/helpers'
import {
    WalletNav,
    WalletTransactions,
    WalletPayouts,
    WalletPayoutCurrent,
    WalletReports,
    WalletWallet,
} from './components'
import { TabItems } from './components/WalletNav/WalletNav'
import style from './Wallet.module.css'

const getUserDefaultCurrency = (state: IRootState) => {
    return state.user?.country?.default_currency
        ? state.user.country.default_currency
        : { id: 0, show_code: '', left_currency_symbol_placement: false }
}

const storageCurrency = StorageService.getItem<ICurrency>(CLIENT_STORAGE_WALLET_CURRENCY_KEY)

const Wallet: React.FC = () => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()

    const {
        id: userCurrencyId,
        show_code: userCurrencyCode,
        left_currency_symbol_placement: userCurrencyLeftSymbol,
    } = useSelector(getUserDefaultCurrency)

    const [activeTabId, setActiveTabId] = useState(TabItems.transactions)
    const [payout, setPayout] = useState<IPayout>()
    const [payouts, setPayouts] = useState<IPayout[]>([])
    const [currency, setCurrency] = useState<Partial<ICurrency>>(storageCurrency || {
        id: userCurrencyId,
        showCode: userCurrencyCode,
        left_currency_symbol_placement: userCurrencyLeftSymbol,
    })

    const [isShowWallet, setIsShowWallet] = useState(false)

    const totalSumCurrency = useMemo(() => {
        const sum = payouts.reduce((acc, item) => {
            return item.convertedBalanceAmount ? acc + item.convertedBalanceAmount : acc
        }, 0)

        const { showCode = '', left_currency_symbol_placement: leftSymbolPlacement = false, roundScale } = currency
        const fractionDigits = getCountFractionDigits(roundScale)
        const options = { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }

        return priceFormat(sum, { symbol: showCode, leftSymbolPlacement, isoCode: '' }, options)
    }, [payouts])

    const handlerChangeTab = (id: number) => {
        setActiveTabId(id)
        setIsShowWallet(id === TabItems.reports)
    }

    const handlerChangeCurrency = (data: ICurrency) => {
        setCurrency(data)
        StorageService.setItem(CLIENT_STORAGE_WALLET_CURRENCY_KEY, data)
    }

    const handlerClickWallet = () => {
        if (activeTabId === TabItems.transactions) {
            setPayout(undefined)
            setIsShowWallet(false)
        }
    }
    /**
     * @deprecated
     */
    const handlerClickReport = (report?: IReport) => {
        // if (report && (report.type === 'folder' || report.type === 'folder_narrow')) {
        //     setReportFolder(report)
        // } else {
        //     setReportFolder(undefined)
        // }
    }

    const handlerClickPayout = (data: IPayout) => {
        setPayout(data)
        setIsShowWallet(true)
        scrollTop()
    }

    const handlerLoadPayouts = (data: IPayout[]) => {
        setPayouts(data)
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <>
            <ContentContainer classes={style.content} size="half">
                <ContentBackgroundBottom isWidthGutter />

                {payout ? (
                    <WalletPayoutCurrent payout={payout} />
                ) : (
                    <WalletNav
                        activeTabId={activeTabId}
                        currencyId={currency.id}
                        onChangeCurrency={handlerChangeCurrency}
                        onChangeTab={handlerChangeTab}
                    >
                        <div className={style.amountSum}>
                            {totalSumCurrency}
                        </div>
                        <div className={style.amountDesc}>
                            {t('payout_total_funds')}
                        </div>
                    </WalletNav>
                )}

                {windowWidth < BREAKPOINTS.desktop && (
                    <div className={style.mobileBar}>
                        {activeTabId === TabItems.transactions && currency.id && (
                            <div className={style.payoutsMobile}>
                                <WalletPayouts
                                    activeClass={style.payoutActive}
                                    active={payout}
                                    currencyId={currency.id}
                                    onLoad={handlerLoadPayouts}
                                    onClick={handlerClickPayout}
                                />
                            </div>
                        )}
                    </div>
                )}

                {activeTabId === TabItems.transactions && (
                    <WalletTransactions classes={style.transactions} currencyId={payout?.currencyId} />
                )}

                {activeTabId === TabItems.reports && (
                    <WalletReports classes={style.reports} onClick={handlerClickReport} />
                )}
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && (
                <SideBarContainer position="right">
                    {isShowWallet && (
                        <WalletWallet
                            classes={style.wallet}
                            amount={totalSumCurrency}
                            onClick={handlerClickWallet}
                        />
                    )}

                    {activeTabId === TabItems.transactions && currency.id && (
                        <WalletPayouts
                            activeClass={style.payoutActive}
                            active={payout}
                            currencyId={currency.id}
                            onLoad={handlerLoadPayouts}
                            onClick={handlerClickPayout}
                        />
                    )}
                </SideBarContainer>
            )}
        </>
    )
}

export default Wallet
