import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ICalendarMonth, ICalendarDay, ICalendarPost } from 'interfaces'
import { defaultDateFormat, dateTimeFormat } from 'utils/helpers'
import { CalendarGridMonth } from '../index'
import { useCalendar } from '../../hooks'
import style from './CalendarYearMonth.module.css'

type CalendarYearMonthPropType = {
    classes?: string
    data: ICalendarMonth
    posts?: ICalendarPost[]
    onClickDay: (value: Date, events: ICalendarPost[]) => void
}

const CalendarYearMonth: React.FC<CalendarYearMonthPropType> = ({
    classes,
    data,
    posts,
    onClickDay,
}) => {
    const { i18n } = useTranslation()

    const [days, setDays] = useState<ICalendarDay[]>([])
    const [daysPosts, setDaysPosts] = useState<Record<string, ICalendarPost[]>>({})

    const { getMonthDays, getDaysPosts } = useCalendar()

    const date = useMemo(() => {
        return dateTimeFormat(data.date, i18n.language, { month: 'long' })
    }, [data, i18n.language])

    const handlerClickDay = (value: Date) => {
        const id = defaultDateFormat(value)
        onClickDay(value, daysPosts[id])
    }

    useEffect(() => {
        setDays(getMonthDays(data.date))
    }, [data])

    useEffect(() => {
        if (posts) {
            setDaysPosts(getDaysPosts(days, posts, false))
        }
    }, [days, posts])

    return (
        <div className={classes}>
            <div className={style.date}>
                {date}
            </div>
            <CalendarGridMonth
                classesWeekday={style.greedWeekday}
                classesDate={style.greedDate}
                days={days}
                daysPosts={daysPosts}
                isShowPrevMonth={false}
                onClickDay={handlerClickDay}
            />
        </div>
    )
}

export default CalendarYearMonth
