import React, { useEffect } from 'react'
import cn from 'classnames'

import { IPayout } from 'interfaces'
import { Loader } from 'components'
import { WalletPayout } from '../index'
import { useFetchPayoutOptions } from '../../hooks'
import style from './WalletPayouts.module.css'

type WalletPayoutsPropType = {
    active?: IPayout
    activeClass?: string
    currencyId: number
    onLoad?: (data: IPayout[]) => void
    onClick?: (data: IPayout) => void
}

const WalletPayouts: React.FC<WalletPayoutsPropType> = ({
    active,
    activeClass = '',
    currencyId,
    onLoad = () => {},
    onClick = () => {},
}) => {
    const { isInitialLoading: isLoading, data: payoutData } = useFetchPayoutOptions({
        converted_balance_currency_id: currencyId,
    }, {
        enabled: !!currencyId,
    })

    useEffect(() => {
        if (payoutData) {
            onLoad(payoutData)
        }
    }, [payoutData])

    return (
        <>
            {isLoading && (
                <Loader classes={style.loader} />
            )}

            {payoutData?.map((item, index) => (
                <WalletPayout
                    classes={cn(style.payout, { [activeClass]: active?.currencyId === item.currencyId })}
                    payout={item}
                    index={index + 1}
                    key={item.currencyId}
                    onClick={onClick}
                />
            ))}
        </>
    )
}

export default WalletPayouts
