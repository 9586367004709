import React from 'react'
import cn from 'classnames'

import style from './BlockHeader.module.css'

export type BlockHeaderPropType = {
    classes?: string
    classesTitle?: string
    classesContent?: string
    title?: string
    titlePos?: 'left' | 'center' | 'right'
}

const BlockHeader: React.FC<BlockHeaderPropType> = ({
    children,
    classes,
    classesTitle,
    classesContent,
    title,
    titlePos = 'left',
}) => {
    return (
        <div className={cn(style.header, classes)}>
            {title !== undefined && (
                <div className={cn(style.title, style[`title_${titlePos}`], classesTitle)}>
                    {title}
                </div>
            )}
            {children && (
                <div className={cn(style.content, classesContent)}>
                    {children}
                </div>
            )}
        </div>
    )
}

export default BlockHeader
