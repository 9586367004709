import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICommunity, IPost } from 'interfaces'
import { POST_COUNT_ACTIVE_COMMUNITY } from 'config/app'
import { CardContainer, Block } from 'layout'
import { PostFormFieldCommunity, ItemCommunity } from 'containers/PostForm/components'
import { useFetchCommunity } from 'containers/Community/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import { Avatar, Button, Modal } from 'components'
import { formDataInitial } from 'forms/PostForm/PostForm'
import { PostAction } from 'form-actions'
import styleCommon from 'styles/modules/common.module.css'
import styleOverlay from 'styles/modules/overlay.module.css'
import style from './PostForm.module.css'

type PostFormPropType = {
    classes?: string
    onComplete?: (post: IPost) => void
}

const PostForm: React.FC<PostFormPropType> = ({ classes, onComplete = () => {} }) => {
    const { t } = useTranslation()

    const profile = useSelector(userSelectors.profile)

    const [postProps, setPostProps] = useState(formDataInitial)
    const [communities, setCommunities] = useState<ICommunity[]>([])

    const [isOpenModalPostForm, setIsOpenModalPostForm] = useState(false)
    const [isOpenModalCommunity, setIsOpenModalCommunity] = useState(false)

    const { data: dataCommunity } = useFetchCommunity({ can_publish: true })

    const community = useMemo(() => {
        if (communities.length) {
            const { community: communityId } = postProps || {}
            const postCommunity = communityId
                ? communities.find((item) => item.community.id === communityId)
                : undefined

            return postCommunity?.community.title
        }
        return postProps.community ? null : undefined
    }, [postProps])

    const isActiveCommunitySelect = useMemo(() => {
        return !('id' in postProps) && communities.length > POST_COUNT_ACTIVE_COMMUNITY
    }, [postProps, communities])

    const isDisabledForm = useMemo(() => !community, [community])

    const handlerOpenModalPostForm = () => {
        setIsOpenModalPostForm(true)
    }

    const handlerCloseModalPostForm = () => {
        closeModalPostForm()
    }

    const handlerModalCommunity = () => {
        setIsOpenModalCommunity((prevState) => !prevState)
    }

    const handlerCompletePost = (post: IPost) => {
        closeModalPostForm()
        onComplete(post)
    }

    const handlerSelectCommunity = (value: ICommunity) => {
        setPostProps((prevState) => ({ ...prevState, community: value.community.id }))
        setIsOpenModalCommunity(false)
    }

    function closeModalPostForm() {
        setIsOpenModalPostForm(false)
    }

    useEffect(() => {
        if (dataCommunity) {
            const [defaultCommunity] = dataCommunity

            if (defaultCommunity) {
                setCommunities(dataCommunity)
                setPostProps((prevState) => ({ ...prevState, community: defaultCommunity.community.id }))
            }
        }
    }, [dataCommunity])

    return (
        <>
            <div className={cn(style.postForm, { [styleCommon.updating]: isDisabledForm }, classes)}>
                <Block classes={style.form}>
                    <div className={style.header}>
                        <Avatar
                            src={profile?.photo}
                            width={54}
                            height={54}
                            name={profile?.name}
                            surname={profile?.surname}
                        />
                        <Button
                            classes={style.buttonAddPost}
                            styleType="transparent"
                            size="size40"
                            text={t('Make new post')}
                            disabled={isDisabledForm}
                            onClick={handlerOpenModalPostForm}
                        />
                    </div>

                    <div className={style.body}>
                        <PostFormFieldCommunity
                            isActive={isActiveCommunitySelect}
                            community={community}
                            onClick={handlerModalCommunity}
                        />
                    </div>
                </Block>
            </div>

            <Modal
                isOpen={isOpenModalPostForm}
                size="medium"
                classesOverlay={styleOverlay.overlay_light}
                onClose={handlerCloseModalPostForm}
            >
                <Modal.Header
                    titlePos="left"
                    title={'id' in postProps ? t('ui_posts_edit_post_title') : t('ui_posts_create_post_title')}
                    isCloseButton
                />
                <PostAction
                    isActiveCommunity={isActiveCommunitySelect}
                    postProps={postProps}
                    communities={communities}
                    onComplete={handlerCompletePost}
                />
            </Modal>

            <Modal
                isOpen={isOpenModalCommunity}
                size="medium"
                classesOverlay={styleOverlay.overlay_light}
                onClose={handlerModalCommunity}
            >
                <Modal.Header
                    isCloseButton
                    titlePos="left"
                    title={t('ui_posts_spinner_group_hint')}
                />
                <Modal.Body background>
                    <CardContainer>
                        {communities.map((item) => (
                            <ItemCommunity
                                data={item}
                                key={item.community.id}
                                onClick={handlerSelectCommunity}
                            />
                        ))}
                    </CardContainer>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PostForm
