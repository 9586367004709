import React, { useMemo, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBreadcrumb } from 'interfaces'
import { DatePairType } from 'components/DateRangeFilter/DateRangeFilter'
import { DatePeriod, AppScheme, AppTitle } from 'enums'
import { APP_URL } from 'config/app'
import { Block } from 'layout'
import { ReportsFreedomChart, ReportsFreedomRankCard } from 'containers/ReportsFreedom/components'
import {
    useFetchMlmReportsRanksCurrent,
    useFetchMlmReportsLoginGraph,
    useFetchMlmReportsActivePartnersGraph,
} from 'containers/ReportsFreedom/hooks'
import {
    PageTitle,
    Breadcrumbs,
    DateRangeFilter,
    Loader,
    NoDataInfo,
    Button,
} from 'components'
import { getMonthDateRange, numberFormat } from 'utils/helpers'
import style from './ReportsFreedomActivePartners.module.css'
import styleReports from '../ReportsFreedom/ReportsFreedom.module.css'

enum DatePeriodRanks {
    current = 'current',
    prev = 'prev',
}

const ACTIVITY_DATE_MIN = '2020-10-08'
const DATE_CURRENT = new Date()
const DATES_MONTH_PREV = getMonthDateRange(new Date(DATE_CURRENT.getFullYear(), DATE_CURRENT.getMonth(), 0))
const DATES_MONTH_CURRENT = getMonthDateRange(DATE_CURRENT)

const ReportsFreedomActivePartners: React.FC = () => {
    const { state }: { state?: { type?: keyof typeof AppScheme} } = useLocation()
    const { t } = useTranslation()

    const [type] = useState<keyof typeof AppScheme>(state?.type ?? AppScheme.pv)
    const [datePeriodPartners, setDatePeriodPartners] = useState<DatePeriod>()
    const [datePeriodActivity, setDatePeriodActivity] = useState<DatePeriod>()
    const [datePeriodRanks, setDatePeriodRanks] = useState<DatePeriodRanks>(DatePeriodRanks.current)
    const [dateFilterPartners, setDateFilterPartners] = useState<DatePairType>()
    const [dateFilterActivity, setDateFilterActivity] = useState<DatePairType>()
    const [dateFilterRanks, setDateFilterRanks] = useState<DatePairType>(DATES_MONTH_CURRENT)

    const [breadcrumbs] = useState<IBreadcrumb[]>([
        { id: 1, name: t('reports'), url: APP_URL.reports },
        { id: 2, name: AppTitle[type] },
        { id: 3, name: t('active_partners') },
    ])

    const defaults = {
        color: type === AppScheme.pv ? '#00a8e1' : '#ff4e1f',
    }

    const {
        isInitialLoading: isLoadingPartners,
        isFetching: isFetchingPartners,
        data: dataReportsFreedomActivePartnersGraph,
    } = useFetchMlmReportsActivePartnersGraph({
        type,
        dateFrom: dateFilterPartners?.dateFrom,
        dateTo: dateFilterPartners?.dateTo,
    }, {
        keepPreviousData: true,
    })

    const {
        isInitialLoading: isLoadingActivity,
        isFetching: isFetchingActivity,
        data: dataReportsFreedomLoginGraph,
    } = useFetchMlmReportsLoginGraph({
        type,
        dateFrom: dateFilterActivity?.dateFrom,
        dateTo: dateFilterActivity?.dateTo,
    }, {
        keepPreviousData: true,
    })

    const { data: dataReportsRanksCurrent } = useFetchMlmReportsRanksCurrent({
        type,
        dateFrom: dateFilterRanks?.dateFrom,
        dateTo: dateFilterRanks?.dateTo,
    }, {
        keepPreviousData: true,
    })

    const chartTotalActivePartners = useMemo(() => {
        const { total } = dataReportsFreedomActivePartnersGraph || {}
        return total !== undefined
            ? `${numberFormat(total)} ${t('report_freedom_partner_count')}`
            : undefined
    }, [dataReportsFreedomActivePartnersGraph])

    const chartTotalLogin = useMemo(() => {
        const { total } = dataReportsFreedomLoginGraph || {}
        return total !== undefined
            ? `${numberFormat(total)} ${t('report_freedom_partner_count')}`
            : undefined
    }, [dataReportsFreedomLoginGraph])

    const periodActiveStyle = useMemo(() => {
        return {
            backgroundColor: defaults.color,
            borderColor: defaults.color,
        }
    }, [type])

    const handlerChangeDateFilterPartners = (dates: DatePairType, period: DatePeriod) => {
        setDateFilterPartners(dates)
        setDatePeriodPartners(period)
    }

    const handlerChangeDateFilterActivity = (dates: DatePairType, period: DatePeriod) => {
        setDateFilterActivity(dates)
        setDatePeriodActivity(period)
    }

    const handlerChangeDateFilterRanks = ({ currentTarget }: React.SyntheticEvent<HTMLButtonElement>) => {
        const { name } = currentTarget

        if (name === DatePeriodRanks.current) {
            setDatePeriodRanks(DatePeriodRanks.current)
            setDateFilterRanks(DATES_MONTH_CURRENT)
        }
        if (name === DatePeriodRanks.prev) {
            setDatePeriodRanks(DatePeriodRanks.prev)
            setDateFilterRanks(DATES_MONTH_PREV)
        }
    }

    return (
        <>
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            <Block classes={styleReports.block}>
                <Block.Header title={dataReportsFreedomActivePartnersGraph?.title ?? ''} />
                <DateRangeFilter
                    activeColor={dataReportsFreedomActivePartnersGraph?.color}
                    onChange={handlerChangeDateFilterPartners}
                />
                <div
                    className={cn(
                        styleReports.chartTotal,
                        { [styleReports.chartTotal_updating]: isFetchingPartners },
                    )}
                    style={{ color: dataReportsFreedomActivePartnersGraph?.color }}
                >
                    {chartTotalActivePartners}
                </div>
                {isLoadingPartners && (
                    <Loader />
                )}
                {dataReportsFreedomActivePartnersGraph && (
                    <ReportsFreedomChart
                        data={dataReportsFreedomActivePartnersGraph}
                        period={datePeriodPartners}
                        isLoading={isFetchingPartners}
                    />
                )}
            </Block>
            <Block classes={styleReports.block}>
                <Block.Header title={dataReportsFreedomLoginGraph?.title ?? ''} />
                <DateRangeFilter
                    activeColor={dataReportsFreedomLoginGraph?.color}
                    minDate={ACTIVITY_DATE_MIN}
                    onChange={handlerChangeDateFilterActivity}
                />
                <div
                    className={cn(
                        styleReports.chartTotal,
                        { [styleReports.chartTotal_updating]: isFetchingActivity },
                    )}
                    style={{ color: dataReportsFreedomLoginGraph?.color }}
                >
                    {chartTotalLogin}
                </div>
                {isLoadingActivity && (
                    <Loader />
                )}
                {dataReportsFreedomLoginGraph && (
                    <ReportsFreedomChart
                        data={dataReportsFreedomLoginGraph}
                        period={datePeriodActivity}
                        isLoading={isFetchingActivity}
                    />
                )}
            </Block>
            <Block classes={styleReports.block}>
                <Block.Header title={t('reports_freedom_motivate_partners_title')} />
                <div className={styleReports.blockSubTitle}>
                    {t('reports_freedom_motivate_partners_description')}
                </div>
                {type === 'pv' && (
                    <div className={style.periodsActions}>
                        <Button
                            classes={cn(
                                style.periodsAction,
                                { [style.periodsAction_active]: datePeriodRanks === DatePeriodRanks.current },
                            )}
                            style={datePeriodRanks === DatePeriodRanks.current ? periodActiveStyle : undefined}
                            styleType="bordered2"
                            size="size36"
                            name={DatePeriodRanks.current}
                            onClick={handlerChangeDateFilterRanks}
                        >
                            {t('report_freedom_current_month')}
                        </Button>
                        <Button
                            classes={cn(
                                style.periodsAction,
                                { [style.periodsAction_active]: datePeriodRanks === DatePeriodRanks.prev },
                            )}
                            style={datePeriodRanks === DatePeriodRanks.prev ? periodActiveStyle : undefined}
                            styleType="bordered2"
                            size="size36"
                            name={DatePeriodRanks.prev}
                            onClick={handlerChangeDateFilterRanks}
                        >
                            {t('report_freedom_previous_month')}
                        </Button>
                    </div>
                )}
                <div className={cn(styleReports.blockBody, styleReports.rankList)}>
                    {dataReportsRanksCurrent?.map((item) => (
                        <NavLink
                            className={styleReports.rankListItem}
                            to={{
                                pathname: APP_URL.reportsFreedomActivePartnersUsers,
                                state: { type, rank: item, dateFilter: dateFilterRanks },
                            }}
                            key={item.id}
                        >
                            <ReportsFreedomRankCard
                                data={item}
                            />
                        </NavLink>
                    ))}
                    {!dataReportsRanksCurrent && (
                        <NoDataInfo text={t('nothing_found')} />
                    )}
                </div>
            </Block>
        </>
    )
}

export default ReportsFreedomActivePartners
