import React, { useContext } from 'react'
import cn from 'classnames'

import { SvgResource } from 'components'
import { Context } from '../Context'
import style from './CustomSelectField.module.css'

export type CustomSelectFieldType = {
    classes?: string
    showArrow?: boolean
    disabled?: boolean
    onClick?: () => void
}

const CustomSelectField: React.FC<CustomSelectFieldType> = ({
    children,
    classes,
    showArrow = true,
    disabled,
    onClick = () => {},
}) => {
    const { isOpen, setOpen } = useContext(Context)

    const handlerClick = () => {
        setOpen(!isOpen)
        onClick()
    }

    return (
        // eslint-disable-next-line
        <div className={cn(style.field, classes, { [style.disabled]: disabled })} onClick={handlerClick}>
            <div className={style.fieldRow}>
                {children}
            </div>
            {showArrow && !disabled && (
                <SvgResource
                    classes={style.fieldArrow}
                    resourceKey="dropdpwn_daw_svg"
                    isImgTag={false}
                    width={16}
                    height={9}
                />
            )}
        </div>
    )
}

export default CustomSelectField
